import { Injectable } from '@angular/core'

import { CountInventoryDto, FindInventoryItemDto, InventoryItemDto, PageResultDto, UpdateInventoryItemDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class InventoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find(searchInventoryItemDto: FindInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/items', searchInventoryItemDto)
  }

  async findLowStock(searchInventoryItemDto: FindInventoryItemDto) {
    return await this.httpService.get<PageResultDto<InventoryItemDto>>('/merchant/v1/inventory/low-stock-items', searchInventoryItemDto)
  }

  async update(updateInventoryItemDto: UpdateInventoryItemDto) {
    return await this.httpService.put<boolean>('/merchant/v1/inventory/items', updateInventoryItemDto)
  }

  async count() {
    return await this.httpService.get<CountInventoryDto>('/merchant/v1/inventory/count')
  }
}
